/** @jsx jsx */
import { responsive, Stack } from "@boxoffice/screenplay";
import { graphql, useStaticQuery } from "gatsby";
import { SocialLinks } from "gatsby-theme-social-links";
import React, { memo } from "react";
import { Icon, mdiMail, mdiPhone } from "shared/components/Icon";
import Link from "shared/components/Link";
import ParsedHTML from "shared/components/ParsedHTML";
import TheaterAddress from "shared/components/TheaterAddress";
import { getTinyColor } from "shared/helpers/colors";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx, Themed } from "theme-ui";

import { TheaterInfosQuery } from "../../../../__generated__/gatsby.types";
import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetTheaterInfo" }>;
}

const TheaterInfoWidget: React.FC<Props> = ({
  isFirst,
  isNested,
  isLast,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const pageContext = usePageContext();
  const [selectedTheaterId] = useSelectedTheaterId();
  const resolvedSelectedTheaterId = pageContext.theaterId || selectedTheaterId;

  const data = useStaticQuery<TheaterInfosQuery>(graphql`
    query TheaterInfos {
      allTheater {
        nodes {
          id
          name
          practicalInfo {
            email
            phone
            location {
              address
              city
              state
              zip
            }
            localeData {
              description
            }
            links {
              facebook
              instagram
              pinterest
              linkedin
              snapchat
              tumblr
              twitter
              vk
              wechat
              letterboxd
              youtube
            }
          }
        }
      }
    }
  `);

  const selectedTheaterData = !pageContext.isSingleLocation
    ? data.allTheater.nodes.find(({ id }) => id === resolvedSelectedTheaterId)
    : data.allTheater.nodes[0];

  const shape = widget.theaterInfoShape;
  const practicalInfo = selectedTheaterData?.practicalInfo;

  const showName = !!(shape?.name && selectedTheaterData?.name);
  const showDescription = !!(
    shape?.description && practicalInfo?.localeData?.description
  );
  const showAddress = !!(shape?.address && practicalInfo?.location);
  const showPhone = !!(shape?.phone && practicalInfo?.phone);
  const showEmail = !!(shape?.email && practicalInfo?.email);
  const showSocial = !!(
    shape?.social &&
    Object.values(practicalInfo?.links || {}).filter(Boolean).length > 0
  );
  const showSocialDivider = [
    showName,
    showDescription,
    showAddress,
    showPhone,
    showEmail,
  ].some(Boolean);

  return (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <Stack space={3}>
        {showName && (
          <Themed.h1 sx={{ fontSize: responsive({ xs: 4, md: 5 }) }}>
            {selectedTheaterData?.name}
          </Themed.h1>
        )}

        {showDescription && (
          <ParsedHTML>
            {practicalInfo?.localeData?.description || ""}
          </ParsedHTML>
        )}

        {showAddress && <TheaterAddress location={practicalInfo?.location} />}

        {showPhone && (
          <div>
            <Link
              icon={<Icon path={mdiPhone} size={1} />}
              to={`tel:${practicalInfo?.phone}`}
            >
              {practicalInfo?.phone}
            </Link>
          </div>
        )}

        {showEmail && (
          <div>
            <Link
              to={`mailto:${practicalInfo?.email}`}
              icon={<Icon path={mdiMail} size={1} />}
            >
              {practicalInfo?.email}
            </Link>
          </div>
        )}

        {showSocial && (
          <div
            sx={
              showSocialDivider
                ? {
                    borderTopWidth: "small",
                    borderTopColor: (theme) => {
                      const bg = getTinyColor(theme.rawColors?.background);
                      return bg.isLight()
                        ? bg.shade(15).toHexString()
                        : bg.tint(15).toHexString();
                    },
                    borderTopStyle: "solid",
                    paddingTop: 3,
                  }
                : undefined
            }
          >
            <div sx={{ fontSize: "1.5em" }}>
              <SocialLinks
                links={practicalInfo?.links}
                size={"medium"}
                withBackground
              />
            </div>
          </div>
        )}
      </Stack>
    </WidgetWrapper>
  );
};

export const query = graphql`
  fragment WidgetTheaterInfo on WidgetTheaterInfo {
    id
    __typename
    theaterInfoShape {
      name
      description
      address
      email
      phone
      social
    }
  }
`;

export default memo(TheaterInfoWidget);
